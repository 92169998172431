<template>
  <div class="doneOrderList">
    <div class="downOrderListTops">
      <div class="doneOrderList_searchBox">
        <search-v1
            class="doneOrderList_searchBox_item"
            v-model="keyword"
            @search="search"
            @clear="search"
        ></search-v1>
      </div>
      <div class="date-ranger-container">
        <date-ranger-v1 @chooseDate="chooseDate"></date-ranger-v1>
      </div>
      <div class="doneOrderList_tips">
        <div class="doneOrderList_tip_top">
          <div class="doneOrderList_tip_top_left">
            <div class="tip_top_left_first"><img style="width:18px;vertical-align:middle;"
                                                 :src="require('@STATIC/image/icons/rmb.png')"/>
              我的佣金（元）
            </div>
            <div class="tip_top_left_second"><span style="font-size: 10px">￥</span>{{ commission }}</div>
          </div>
          <div class="doneOrderList_tip_top_right">
            <div @click="goToWithMoney" class="tip_top_button">去提现</div>
          </div>
        </div>
        <div class="doneOrderList_tip_down">
          <div class="doneOrderList_tip_down_left">
            <div style="line-height: 24px; height: 24px;">配送单数（{{ total_count }}单）</div>
          </div>
          <div class="doneOrderList_tip_down_right">
            <div style="line-height: 24px; height: 24px;">核销商品数（{{ goods_number }}件）</div>
          </div>

        </div>
      </div>
    </div>

    <div style="width:100%;height: 8px;background: #F8F8F8;">&nbsp;</div>

    <div style="background: #F8F8F8;">
      <div>

        <scrollview @onRefresh="onRefresh" @onReload="onReload" :height="scrollHeight">

          <div class="card" v-for="(item, index) in list" :key="index">
            <div class="card-title">
              <div>1.订单编号：{{ item.flow_up_code }}</div>
              <div>佣金：{{ item.site_point_money }}</div>
            </div>
            <div class="card-title-desc" v-for="(goodItem, goodIndex) in item.goodList"
                 :key="goodIndex">
              <div>{{ goodItem.title }}({{ goodItem.spec_text }}）</div>
              <div></div>
            </div>
            <div class="card-title-desc">
              <div>状态：<span style="color:#F46A17">已完成</span></div>
              <div @click="showDetail(index)"><span style="padding-right:5px">订单详情</span>
                <img
                    v-if="show && showIndex==index" style="width:10px;"
                    :src="require('@/static/image/icons/arrowUp.png')"
                />
                <img
                    v-else style="width:10px;"
                    :src="require('@/static/image/icons/arrowDown.png')"
                />

              </div>
            </div>
            <div class="card-title-desc-list" v-if="show && showIndex==index">
              <div v-for="(goodItem, goodIndex) in item.goodList"
                   :key="goodIndex" class="card-title-desc-goods-info">
                <div><img style="width:56px;vertical-align:middle;border-radius:8px;" :src="goodItem.cover">
                </div>
                <div>
                  <span style="color: #303030;font-size: 12px; line-height: 25px;">{{ goodItem.title }}</span>
                  <span>{{ goodItem.spec_text }}</span>
                </div>
                <div>×{{ goodItem.total }}</div>
              </div>

            </div>
            <div class="card-title-desc-info" style="margin-top: 10px" v-if="show && showIndex==index">
              <div class="list-desc-info-for">

                <div class="label" slot="title">联 系 人 ：</div>
                <div class="time">{{ item.real_name }}</div>

              </div>
              <div class="list-desc-info-for">

                <div class="label" slot="title">联系电话：</div>
                <div class="time">{{ item.real_mobile }}</div>

              </div>
              <div class="list-desc-info-for">

                <div class="label" slot="title">到件时间：</div>
                <div class="time">{{ item.site_point_receive_time }}</div>

              </div>
              <div class="list-desc-info-for">

                <div class="label" slot="title">取件时间：</div>
                <div class="time">{{ item.site_point_send_time }}</div>

              </div>
              <div class="list-desc-info-for">
                <div class="label" slot="title">配 送 员 ：</div>
                <div class="time">{{ item.name }}</div>
              </div>
              <div class="list-desc-info-for">
                <div class="label" slot="title">联系电话：</div>
                <div class="time">{{ item.mobile }}</div>
              </div>
            </div>

          </div>

        </scrollview>
      </div>

    </div>

  </div>
</template>

<script>
import SearchV1 from '../../template/community/search/searchV1'
import DateRangerV1 from '../../template/community/date/dateRangerV1'
import CellV3 from '../../template/community/cell/cellV3'
import GoodsV4 from '../../template/community/goods/GoodsV4'

export default {
  name: 'done-order-list',
  components: {CellV3, DateRangerV1, SearchV1, GoodsV4},
  data() {
    return {
      show: false,
      showIndex: -1,
      keyword: '', //关键词
      date: '', //时间
      commission: 0, //佣金
      supper_admin: 0, //是否是管理员
      total_count: 0, //配单数
      valid_num: 0, //已完成订单件数
      goods_number: 0, //商品件数
      params: {
        page: 1,
        page_size: 10,
      },
      list: [],
      scrollHeight: 0
    }
  },
  async mounted() {
    document.title = '已完成订单'
    await this.getOrderList()
    this.scrollHeight = window.innerHeight - document.querySelector('.doneOrderList_searchBox').clientHeight - document.querySelector('.date-ranger-container').clientHeight - document.querySelector('.doneOrderList_tips').clientHeight
  },
  methods: {
    onRefresh(done) {
      this.list = []
      this.params.page = 1
      this.getOrderList().finally(() => {
        done()
      })
    },

    goToWithMoney() {
      //console.log(333333333333333)
      if (this.supper_admin == 1) {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/selfExtract/with-drawal',
          })
        } else {
          this.$router.push({
            path: 'with-drawal',
          })
        }
      } else {
        this.$toast.text('非管理员不能提现')
      }
    },
    showDetail(keyShowIndex) {

      if (this.showIndex == keyShowIndex) {
        //当前位置点击切换
        this.show = !this.show
        if (this.show) {
          this.showIndex = keyShowIndex
        } else {
          this.showIndex = -1
        }
      } else {
        //切换位置后   先恢复位置 再重新设置位置
        this.showIndex = -1
        this.show = true
        this.showIndex = keyShowIndex
      }

    },
    /**
     * step 当前加载结束
     * over 没有更多数据了
     */
    onReload(step, over) {
      console.log('上拉加载')
      this.params.page += 1
      this.getOrderList().finally(() => {
        if (this.list.length < this.params.page * this.params.page_size) {
          over()
        } else {
          step()
        }
      })
    },
    chooseDate(date) {
      this.date = date.toString()
      this.params.page = 1
      this.list = []
      this.commission = 0
      this.total_count = 0
      this.getOrderList()
    },
    search() {
      this.date
      this.params.page = 1
      this.list = []
      this.commission = 0
      this.total_count = 0
      this.getOrderList()
    },
    async getOrderList() {
      let params = {
        ...this.params,
        keyword: this.keyword,
        date: this.date,
      }
      try {
        //this.isHasMore=false;
        let query = await this.$api.community.points.pointFinishOrder(params)
        this.commission = query.data.commission
        this.total_count = query.data.total_count
        this.goods_number = query.data.goods_number
        this.valid_num = query.data.valid_num
        this.supper_admin = query.data.supper_admin
        if (query.data.list.length == 0) {
          return
        }
        this.list = [...this.list, ...query.data.list]
      } catch (error) {
        //
      }
    },
  },
}
</script>

<style scoped lang="scss">
@include b(doneOrderList) {
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .downOrderListTops {
    border: 0px solid #c10000;
    padding-bottom: 15px;
  }
  @include e(searchBox) {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    @include e(item) {
      width: 345px;
      height: 32px;
    }
  }
  @include e(tip) {
    flex-shrink: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 15px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #c10000;
    & > div:last-of-type {
      margin-left: 20px;
    }
  }
  @include e(body) {
    flex-grow: 1;
  }
}

.list-desc-info-for {
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #979797;
  line-height: 24px;
}

.doneOrderList_tips {
  border: 0px solid #c10000;
  width: 345px;
  height: 128px;
  background: linear-gradient(180deg, #FF934E 0%, #F33D40 100%);
  border-radius: 8px;
  margin: auto;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  color: #ffffff;

  .doneOrderList_tip_top {
    width: 345px;
    height: 80px;
    display: flex;

    .doneOrderList_tip_top_left {
      width: 50%;

      .tip_top_left_first {
        padding-left: 15px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        height: 34px;
        line-height: 34px;

      }

      .tip_top_left_second {
        padding-left: 25px;
        height: 52px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 52px;
      }
    }

    .doneOrderList_tip_top_right {
      width: 50%;

      .tip_top_button {
        width: 79px;
        height: 30px;
        background: #FFFFFF;
        border-radius: 15px;
        margin-top: 35px;
        margin-left: 70px;

        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #F46A17;
        line-height: 30px;
        text-align: center;
      }
    }

  }

  .doneOrderList_tip_down {

    width: 345px;
    height: 48px;
    background: linear-gradient(360deg, #F33D40 0%, #FF934E 100%);
    border-radius: 0px 0px 8px 8px;
    display: flex;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    align-items: center;

    .doneOrderList_tip_down_left {
      padding-left: 15px;
      width: 50%;
      height: 48px;
      display: flex;
      align-items: center;
    }

    .doneOrderList_tip_down_right {
      padding-left: 15px;
      width: 50%;
      height: 48px;
      display: flex;
      align-items: center;
    }
  }
}

.date-ranger-container {
  flex-shrink: 0;
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  padding: 10px 15px;
}

.card {
  background: #fff;
  padding: 15px 15px 9px 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 5px;
  margin-bottom: 8px;
  border: 0px solid red;

  .card-title {
    display: flex;
    border: 0px solid #f46a;
    justify-content: space-between;
    margin: 0px;

    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #303030;
      line-height: 24px;
    }

    div:nth-child(2) {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #E40000;
      line-height: 24px;
    }
  }

  .card-title-desc {
    display: flex;
    justify-content: space-between;
    margin: 0px;
    padding-bottom: 10px;

    div:nth-child(1) {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 20px;
    }

    div:nth-child(2) {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #979797;
      line-height: 20px;
    }
  }

  .card-title-desc-list {
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    padding: 10px 0 10px 0;

    .card-title-desc-goods-info {
      display: flex;
      margin-bottom: 5px;

      div:nth-child(1) {
        width: 56px;
        text-align: center;
      }

      div:nth-child(2) {
        border: 0px solid #f46a;
        width: 230px;
        padding-left: 10px;

        span {
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          display: block;
          height: 28px;
          color: #979797;

        }
      }

      div:nth-child(3) {
        border: 0px solid #f46a;
        width: 60px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 56px

      }
    }
  }

}

.card-line {
  margin-bottom: 15px;

  .scan-button {
    color: #f46a17;
    font-size: 14px;

    img {
      width: 14px;
      height: 14px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    color: #f46a17;

    img {
      margin-right: 5px;
      width: 14px;
      height: 14px;
    }
  }

}
</style>
